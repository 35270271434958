import {
  CircularProgress,
  Heading,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import colors from '~/theme/colors';

const RedirectScreen = () => {
  const backgroundColor = useColorModeValue('white', colors.gray[900]);
  const titleColor = useColorModeValue(colors.brand.navy, 'white');
  const trackerColor = useColorModeValue(
    colors.gray['700'],
    colors.brand['navy.darken'],
  );

  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      w="100vw"
      h="100vh"
      background={backgroundColor}>
      <CircularProgress
        thickness="5px"
        size="30px"
        isIndeterminate
        color="brand.perwinkle.500"
        trackColor={trackerColor}
      />
      <Heading color={titleColor}>REDIRECTING...</Heading>
    </HStack>
  );
};

export default RedirectScreen;
