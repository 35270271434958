import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Heading,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import RedirectScreen from '~/components/RedirectScreen';
import colors from '~/theme/colors';

const Custom404Page = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const handleRedirect = () => {
    if (!isAuthenticated && !isLoading) loginWithRedirect();
  };

  const backgroundColor = useColorModeValue('white', colors.gray[900]);
  const titleColor = useColorModeValue(
    colors.brand.navy,
    colors.brand.perwinkle[500],
  );
  const borderColor = useColorModeValue('black', 'white');

  useEffect(() => {
    const timeout = setTimeout(handleRedirect, 300);
    return () => clearTimeout(timeout);
  }, [isLoading, isAuthenticated]);

  return isAuthenticated ? (
    <VStack
      h="100vh"
      alignItems="center"
      justifyContent="center"
      background={backgroundColor}>
      <HStack spacing="6">
        <Heading color={titleColor}>404</Heading>
        <Box h="50px" borderRightWidth="1.5px" borderColor={borderColor} />
        <Text size="2xl">This page could not be found.</Text>
      </HStack>
    </VStack>
  ) : (
    <RedirectScreen />
  );
};

export default Custom404Page;
